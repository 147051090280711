import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const TransactionList = ({ transactions }) => (
    <List>
        {transactions.map((transaction, index) => (
            <ListItem key={index}>{transaction}</ListItem>
        ))}
    </List>
);

export default TransactionList;
