import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './index.scss'

import { eventBusService } from '../../services/event-bus.service.js'

export class UserMsg extends React.Component {
    state = {
        msg: null
    };

    removeEvent;

    componentDidMount() {
        this.removeEvent = eventBusService.on('show-user-msg', (msg) => {
            this.setState({ msg });
            setTimeout(() => {
                this.setState({ msg: null });
            }, 5000);
        });
    }

    componentWillUnmount() {
        this.removeEvent();
    }

    handleClose = () => {
        this.setState({ msg: null });
    };

    render() {
        const { msg } = this.state;
        if (!msg) return null;
        const msgClass = msg.type || '';

        return (
            <Snackbar
                open={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={this.handleClose}
            >
                <SnackbarContent
                    className={msgClass}
                    message={msg.txt}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        );
    }
}

// export class UserMsg extends React.Component {

//     removeEvent;

//     state = {
//         msg: null
//     }

//     componentDidMount() {
//         // Here we listen to the event that we emitted, its important to remove the listener
//         this.removeEvent = eventBusService.on('show-user-msg', (msg) => {
//             this.setState({ msg })
//             setTimeout(() => {
//                 this.setState({ msg: null })
//             }, 5000000)
//         })
//     }

//     componentWillUnmount() {
//         this.removeEvent()
//     }

//     render() {
//         if (!this.state.msg) return <span></span>
//         const msgClass = this.state.msg.type || ''
//         return (
//             <section className={'user-msg ' + msgClass}>
//                 {this.state.msg.txt}
//                 <button onClick={() => {
//                     this.setState({ msg: null })
//                 }}>x</button>
//             </section>
//         )
//     }
// }
