import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import './About.scss';

function About() {
    return (
        <Container className="about-container">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom className="about-title">
                    About Me
                </Typography>
                <Typography variant="body1" gutterBottom className="about-text">
                    My name is Maoz Yosifon, a highly motivated and passionate Full Stack Developer. I graduated from a full stack course in Coding academy with 640+ hours of intensive coding bootcamp, that qualifies Full-Stack developers. I have experience in a variety of technologies including React, Node.js, Vue, and many others. I'm always excited to learn and grow as a professional.
                </Typography>
                <Typography variant="body1" gutterBottom className="about-text">
                    Apart from coding, I enjoy [share a bit about your personal interests here]. Feel free to reach out to me to discuss potential opportunities, collaborations, or if you just want to chat about tech!
                </Typography>
            </Box>
        </Container>
    );
}

export default About;
