import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Drawer,
    List,
    ListItem,
    Button,
    useMediaQuery,
    useTheme,
    Typography,
} from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/maoz-logo.jpeg';
import '../assets/scss/Header.scss';

const Header = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navLinks = [
        { label: 'Home', path: 'home' },
        { label: 'About', path: 'about' },
        { label: 'Skills & Technologies', path: 'skills-technologies' },
        { label: 'Portfolio', path: 'portfolio' },
        { label: 'Experience', path: 'experience' },
        { label: 'Education', path: 'education' },
        { label: 'Contact', path: 'contact' },
    ];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderNavLinks = () => {
        return (
            <div className="navDisplayFlex">
                {navLinks.map((link, index) => (
                    <Button key={index} color="inherit" className="navLink">
                        <ScrollLink to={link.path} smooth={true} duration={500}>
                            {link.label}
                        </ScrollLink>
                    </Button>
                ))}
            </div>
        );
    };

    const renderDrawer = () => {
        return (
            <div>
                <List>
                    {navLinks.map((link, index) => (
                        <ListItem button key={index}>
                            <ScrollLink
                                to={link.path}
                                smooth={true}
                                duration={500}
                                onClick={handleDrawerToggle}
                            >
                                {link.label}
                            </ScrollLink>
                        </ListItem>
                    ))}
                </List>
            </div>
        );
    };

    return (
        <AppBar className="AppBar">
            <Toolbar>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography component="div" textAlign="center">
                        MAOZ YOSIFON
                    </Typography>
                    <Typography color={"black"} component="div" textAlign="center" fontSize="x-small">
                        {'<✐>FULLSTACK</✐>'}
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                {!isMobile && renderNavLinks()}
                <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
                    {renderDrawer()}
                </Drawer>
                {isMobile && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className="navIconHide"
                    >
                        <MenuIcon />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar >
    );
};

export default Header;
