import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function SkillsTechnologies() {
    const skills = ['HTML', 'CSS', 'Sass', 'JavaScript', 'SQL', 'Vue', 'React', 'Angular', 'Node.JS', 'Rest API', 'MongoDB', 'MySQL', 'AJAX', 'PWA', 'Git', 'WebSocket’s', 'jQuery', 'Bootstrap', 'Heroku', 'gRPC', 'VPC Networking (Cloud Technology)'];

    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Skills & Technologies
                </Typography>
                <Grid container spacing={2}>
                    {skills.map((skill, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Paper elevation={3} style={{ padding: '15px', textAlign: 'center' }}>
                                <Typography variant="h6" gutterBottom>{skill}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
}

export default SkillsTechnologies;
