import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

function Education() {
    const educations = [
        {
            course: "Full software stack development course",
            institute: "Coding Academy",
            duration: "May 2022 - Aug 2022",
            description: "Successfully graduated a full software stack development course (frontend and backend). For my final project I have designed and implemented a Trello like service. The service has been used during my subcontractor work at Apple (see above). The tool is called: “Camallo”- and is based on Vue, Node.js and MongoDB."
        },
        {
            course: "MCSA (Microsoft Certified Solutions Associate)",
            institute: "Management College",
            duration: "Dec 2018 - Mar 2019",
            description: "Successfully completed MCSA certification."
        },
        // Add more education here...
    ];

    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Education
                </Typography>
                <List>
                    {educations.map((education, index) => (
                        <div key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={education.course}
                                    secondary={`${education.institute} (${education.duration})`}
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    secondary={education.description}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}
                </List>
            </Box>
        </Container>
    );
}

export default Education;
