import React, { useState } from 'react';
import ExpenseForm from './components/ExpenseForm';
import ExpenseList from './components/ExpenseList';
import CalculationResult from './components/CalculationResult';
import { Container, Paper, Typography } from '@mui/material';

const ExpenseSplitter = () => {
    const [expenses, setExpenses] = useState([]);

    const handleAddExpense = (expense) => {
        setExpenses([...expenses, expense]);
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>Expense Splitter</Typography>
                <ExpenseForm onAddExpense={handleAddExpense} />
                <ExpenseList expenses={expenses} />
                <CalculationResult expenses={expenses} />
            </Paper>
        </Container>
    );
};

export default ExpenseSplitter;
