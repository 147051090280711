import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Header from './components/Header';
import Home from './pages/home/Home';
import About from './pages/about/About';
import SkillsTechnologies from './pages/skills&technologies/SkillsTechnologies';
import Experience from './pages/experience/Experience';
import Education from './pages/education/Education';
import Contact from './pages/contact/Contact';
import ExpenseSplitter from './pages/ExpenseSplitter/ExpenseSplitter';
import './assets/scss/global.scss';
import { UserMsg } from './components/UserMsg';

function App() {
  return (
    <Router>
      <div className="App">
        <UserMsg />
        <Routes>
          <Route exact path="/" element={
            <main className="container">
              <Header />
              <Element name="home"><Home /></Element>
              <Element name="about"><About /></Element>
              <Element name="skills-technologies"><SkillsTechnologies /></Element>
              <Element name="experience"><Experience /></Element>
              <Element name="education"><Education /></Element>
              <Element name="contact"><Contact /></Element>
            </main>
          } />
          <Route path="/expense-splitter" element={<ExpenseSplitter />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
