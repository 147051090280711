import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { httpService } from '../../services/http.service';
import { showUserMsg } from '../../services/event-bus.service';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = async (event) => {
        event.preventDefault();
        const formData = {
            name: name,
            email: email,
            message: message
        }
        try {
            const response = await httpService.post(`/send-email`, formData);
            // const response = await fetch('http://localhost:3030/send-email', {
            //     method: 'POST',
            //     body: formData,
            // });
            console.log('formData', formData);
            console.log('response', response);
            if (response) {
                // Email sent successfully
                // alert('Message sent!');
                showUserMsg('Message sent!', 'success')
                // Reset form fields
                setName('');
                setEmail('');
                setMessage('');
            } else {
                // Error occurred while sending email
                // alert('Failed to send message. Please try again later.');
                showUserMsg('Failed to send message. Please try again later.', 'success')

            }
        } catch (error) {
            // Error occurred while making the request
            console.error('Error:', error);
            showUserMsg('An unexpected error occurred. Please try again later.', 'danger');
        }
    };

    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Contact Me
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <a href="tel:+972523402728">Phone</a> <br />
                    <a href="mailto:maozyosifon@gmail">Email</a> <br />
                    <a href="https://github.com/MaozYosifon">GitHub</a> <br />
                    <a href="https://www.linkedin.com/in/maoz-yosifon-73aa88213/">LinkedIn</a>
                </Typography>
                <form onSubmit={sendEmail} noValidate autoComplete="off">
                    <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id="message"
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button variant="contained" type="submit">
                        Send Message
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default Contact;
