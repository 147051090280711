import React from 'react';
import TransactionList from './TransactionList';
import { Typography } from '@mui/material';

const CalculationResult = ({ expenses }) => {
    if (expenses.length === 0) {
        return <div>No expenses recorded.</div>;
    }

    const totalExpense = expenses.reduce((sum, { amount }) => sum + amount, 0);

    // Calculate the total number of people covered by all expenses
    const totalPeople = expenses.reduce((sum, expense) => sum + expense.peopleCovered, 0);

    // Calculate the cost per person
    const costPerPerson = totalExpense / totalPeople;

    // Determine how much each person owes or is owed
    const personToBalanceMap = new Map();

    expenses.forEach(expense => {
        const amountPerPerson = costPerPerson * expense.peopleCovered;
        personToBalanceMap.set(expense.name, (personToBalanceMap.get(expense.name) || 0) + expense.amount - amountPerPerson);
    });

    const balances = Array.from(personToBalanceMap, ([name, balance]) => ({ name, balance }));

    // Filter out balances that are effectively zero
    const nonZeroBalances = balances.filter(b => Math.abs(b.balance) > 0.01);

    // Sort the non-zero balances to simplify calculation of transactions
    nonZeroBalances.sort((a, b) => a.balance - b.balance);

    const transactions = [];
    let i = 0;
    let j = nonZeroBalances.length - 1;
    let debt;

    while (i < j) {
        debt = Math.min(-nonZeroBalances[i].balance, nonZeroBalances[j].balance);
        transactions.push(`${nonZeroBalances[i].name} pays ${nonZeroBalances[j].name} ${debt.toFixed(2)} Shekels`);

        nonZeroBalances[i].balance += debt;
        nonZeroBalances[j].balance -= debt;

        if (nonZeroBalances[i].balance === 0) {
            i++;
        }
        if (nonZeroBalances[j].balance === 0) {
            j--;
        }
    }

    return (
        <div>
            <Typography variant="h6">Total Expense: {totalExpense.toFixed(2)} Shekels</Typography>
            <Typography variant="h6">Cost per Person: {costPerPerson.toFixed(2)} Shekels</Typography>
            <TransactionList transactions={transactions} />
        </div>
    );
};

export default CalculationResult;
