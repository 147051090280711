import Axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://maoz-website-backend.web.app/'
    : 'https://maoz-website-backend.web.app'
// : '//localhost:3030'

const axios = Axios.create({
    withCredentials: true
})

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    }
}

async function ajax(endpoint, method = 'GET', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            params: (method === 'GET') ? data : null
        })
        return res.data
    } catch (err) {
        handleRequestError(err, method, endpoint, data)
        throw err
    }
}

function handleRequestError(err, method, endpoint, data) {
    console.error(`Failed to ${method} to endpoint: ${endpoint} with data:`, data)
    console.error('Error:', err.message)
    if (err.response && err.response.status === 401) {
        // You can replace this with a router navigation call if your app uses vue-router or similar
        window.location.assign('/#/login')
    }
}
