import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';

const ExpenseList = ({ expenses }) => (
    <List>
        {expenses.map((expense, index) => (
            <ListItem key={index}>
                <Typography variant="body1">
                    {`${expense.name}${expense.peopleCovered > 1 ? ` + ${expense.peopleCovered - 1}` : ''}`}:
                    {` ${expense.amount} Shekels`}
                </Typography>
            </ListItem>
        ))}
    </List>
);

export default ExpenseList;
