import React, { useState } from 'react';
import { TextField, Button, Grid, Paper } from '@mui/material';

const ExpenseForm = ({ onAddExpense }) => {
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const [additionalPeople, setAdditionalPeople] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission action

        if (!name) {
            setError('Please enter a name.');
            return;
        }

        const numericAmount = amount ? parseFloat(amount) : 0;
        if (isNaN(numericAmount) || numericAmount < 0) {
            setError('Please enter a valid amount.');
            return;
        }

        onAddExpense({
            name,
            amount: numericAmount,
            peopleCovered: additionalPeople + 1, // +1 for the person themselves
        });

        // Reset form fields
        setName('');
        setAmount('');
        setAdditionalPeople(0);
        setError('');
    };

    return (
        <Paper style={{ padding: '20px', marginTop: '10px', marginBottom: '20px' }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Name"
                            fullWidth
                            value={name}
                            onChange={e => setName(e.target.value)}
                            error={!!error && !name}
                            helperText={!!error && !name ? error : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Amount"
                            fullWidth
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            type="number"
                            error={!!error && !amount}
                            helperText={!!error && !amount ? error : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Additional People Covered"
                            type="number"
                            value={additionalPeople}
                            onChange={e => setAdditionalPeople(Math.max(0, parseInt(e.target.value, 10)))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>Add Expense</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default ExpenseForm;
