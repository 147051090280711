import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

function Experience() {
    const experiences = [
        {
            role: "Subcontractor for Apple",
            duration: "Dec 2022 – Apr 2023",
            description: "Developed a frontend using React and Node.js for an internal service. The project consisted of defining the requirement of the interface (from an existing CLI tool), designing and fully implementing the system using React and Node.js with a gRPC backend."
        },
        {
            role: "IT manager at EMETOS",
            duration: "Oct 2020 – Dec 2021",
            description: "First contact person for windows server, WiFi APs, HP Switches, Exchange Server."
        },
        // Add more experiences here...
    ];

    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Experience
                </Typography>
                <List>
                    {experiences.map((experience, index) => (
                        <div key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={experience.role}
                                    secondary={experience.duration}
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    secondary={experience.description}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}
                </List>
            </Box>
        </Container>
    );
}

export default Experience;
