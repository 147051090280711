import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Home() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Hi, I'm Maoz
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        A passionate Full Stack Developer with experience in a variety of technologies including React, Node.JS, and Vue.
                    </Typography>
                    {/* <Button variant="contained">See My Work</Button> */}
                </Box>

            </Container>
        </Box>
    );
}
